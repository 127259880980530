
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: rgba(46, 45, 50, 0.85);
    font-weight: 500;
  }
  
  a {
    color: #00447a;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  button:focus {
    outline: none;
  }
  
  /*------------------------------------------------------------------*/
  /* main menu
  /*------------------------------------------------------------------*/
  .header-logo,
  .footer-logo {
    width: 153px;
  }
  
  .btn-sign-in {
    padding: 5px 10px;
    border-radius: 25px;
    border: 1px solid #3c3675;
    color: #3c3675;
    font-weight: bold;
  }
  .btn-sign-in-home {
    padding: 5px 10px;
    border-radius: 25px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-weight: bold;
  }
  .btn-sign-in-home:hover {
    color: #ebebeb !important;
    border: 1px solid #ebebeb;
  }
  .btn-sign-in:hover {
    color: #4d4695;
  }
  .user-icon {
    width: 30px;
  }
  
  .user-icon-lg {
    width: 70px;
  }
  
  .header-dropdown {
    cursor: pointer;
  }
  
  .header-dropdown .dropdown-toggle::after {
    display: none !important;
  }
  
  .header-dropdown-btn:hover {
    color: white;
    background-color: #0097a7;
    border-color: #0097a7;
    box-shadow: 0 2px #2f4d50;
  }
  
  .header-dropdown-btn {
    background-color: #3ac4d2;
    color: white;
    border-radius: 25px;
    padding: 5px 12px;
    cursor: pointer;
    font-weight: bold;
    width: max-content;
    font-size: 0.875rem;
  }
  
  .header-dropdown-body {
    border-radius: 12px;
    right: 0;
    margin-top: 16px;
    left: unset !important;
  }
  
  .temporary-header {
    width: 100%;
    background: #3ac4d2;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 16px 20px;
    font-weight: bold;
    font-size: 1.2em;
    top: 0;
    z-index: 1000;
  }
  
  .navbar-nav-v2 .nav-link {
    color: #2E2D32 !important;
  }
  
  .navbar-dark-color .nav-link {
    color: #3c3676;
  }
  .txt-dark-1 {
    color: #2E2D32 !important;
  }
  
  .navbar-nav {
    align-items: center;
  }
  
  .navbar-nav .nav-link {
    letter-spacing: 0.02em;
    font-size: 1rem;
    font-weight: 500;
    padding-top: 20px;
  }
  
  .navbar-nav .nav-link:hover {

    text-shadow: unset;
  }
  .navbar-nav .active > a {
    text-shadow: unset;
    
    font-weight: 700;
    color: #3c3675 !important;
     
  }
  
  .navbar-nav .nav-link::after {
    content: '';
    width: 50%;
    height: 4px;
    border-radius: 3px;
    margin-top: 3px;
    display: block;
    background: transparent;
  }
  
  .navbar-nav .nav-link:hover::after {
    background: #ccc;
  }
  
  .navbar-nav .active > a::after {
    background: #8adce7 !important;
  }
  
  .navbar-nav .active-v2 > a::after {
    background: var(--dark-color) !important;
  }
  
  .navbar-toggler {
    border: none !important;
    padding: 0;
  }
  
  .navbar-toggler img {
    width: 40px;
  }
  
  .select-language {
    margin-top: 5px;
    background: #d8d8d8;
    border-radius: 5px;
    border: none;
  }
  
  @media (max-width: 993px) {
    .btn-sign-in-container {
      margin-bottom: 8px;
    }
    .active-v2 > a {
      text-shadow: none;
      font-weight: bold;
    }
    .header-dropdown {
      margin-top: 16px;
      margin-bottom: 6px;
    }
    .btn-sign-in-home {
      border: 1px solid #3c3675;
      color: #3c3675;
    }
    .navbar {
      margin-bottom: -10px;
    }
    .navbar-collapse {
      background-color: var(--light-color);
      margin: 0 -40px;
      padding: 10px 40px;
    }
    .navbar-nav {
      align-items: unset;
    }
    .navbar-nav .nav-link {
      color: var(--dark-color) !important;
      border-bottom: 1px solid var(--dark-color);
      padding-top: 0.5rem;
    }
    .navbar-nav .nav-link:hover,
    .navbar-nav .active > a {
      text-shadow: none;
      font-weight: bold;
    }
  
    .navbar-nav .nav-link::after {
      display: none;
    }
  
    header {
      width: 100%;
      height: 49px;
    }
  
    header .sticky-ld {
      width: 100%;
      position: fixed;
      z-index: 999;
      /* background: #fffaef; */
      background: #ffff;
    }
  }
  
  @media (max-width: 767px) {
    .navbar-collapse {
      margin: 0 -15px;
      padding: 10px 15px;
    }
  }
  
  /*------------------------------------------------------------------*/
  /* page footer
  /*------------------------------------------------------------------*/
  
  footer {
    background-color: var(--light-color);
    color: #3c3676;
    font-size: 1em;
  }
  
  footer ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  footer li {
    margin-top: 5px;
  }
  
  footer li a {
    color: #3c3676 !important;
  }
  
  footer li a:hover {
    font-weight: 600;
  }

  footer li div:hover {
    font-weight: 600;
  }
  
  .page-footer {
    background: #3c3676;
    color: white;
    padding: 25px 0;
  }
  
  .social-icons {
    float: right;
    margin-top: -8px;
  }
  
  .contact-info {
    display: flex;
    width: 300px;
    justify-content: flex-start;
    align-items: center;
  }
  
  .contact-info img {
    margin: 0px !important;
    width: 16px;
  }
  .contact-info > p {
    margin-bottom: 0px !important ;
    margin-left: 20px !important;
  }
  
  .contact-info div {
    display: inline-flex;
  }
  
  .social-icons a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    background: white;
    color: #3c3676;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 5px;
  }
  
  .social-icons a:hover {
    background: #fffaef;
    color: red;
  }
  
  @media (max-width: 767px) {
    footer b {
      border-bottom: 0.5px solid var(--dark-color);
      margin-bottom: 5px;
      padding-bottom: 6px;
      display: block;
    }
    footer .company-links b {
      border-bottom: none;
      margin-bottom: 0;
    }
  
    footer b::after {
      content: '+';
      float: right;
      font-size: 1.8em;
      font-weight: normal;
      margin-top: -6px;
    }
  
    footer .links {
      display: none;
    }
  
    footer .display .links {
      display: block;
      border-bottom: 0.5px solid var(--dark-color);
      margin-bottom: 5px;
      padding-bottom: 25px;
    }
  
    footer .display:last-child .links {
      border-bottom: none;
    }
  
    footer .display b {
      border-bottom: none;
    }
  
    footer .display b::after {
      display: none;
    }
  
    .page-footer {
      padding: 15px 0;
    }
    .social-icons {
      float: none;
      margin-top: 8px;
    }
    .social-icons a {
      margin-left: 0;
      margin-right: 8px;
    }
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  img {
    max-width: 100%; /* or max-width: 100%; */
    height: auto;
  }
  

.main-text-color {
    color: #3C3675 !important;
  }
  .font-weight-semi-bold {
    font-weight: 600 !important;
  }

.txt-small {
    font-size: 10px;
  }
  .main-txt-color-1 {
    color: #2E2D32;
  }
  
  .txt-medium {
    font-size: 18px;
  }
  .txt-address {
    font-size: 14px;
  }

  .title.v2 {
    font-size: 3.25rem;
    line-height: 4rem;
  }
  .btn.v2 {
    font-size: 16px;
  }
  
  .btn-download {
    background-image: linear-gradient(180deg, #32384B 0%, #0A0F1B 100%) !important;
    border-radius: 8px;
    cursor: pointer;
  }
  .bg-dark-blue {
    background-color: #000172;
  }
  @media (max-width: 700px) {
    
    .title.v2 {
      font-size: 2.25rem;
      line-height: 2rem;
    }
    .title.v3 {
      font-size: 2rem !important;
      line-height: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
  .text-blue-custom {
    color: #3c3676;
  }
