/* Loading animation */
.container-loading {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 1999999999;
    justify-content: center;
    align-items: center;
    background-color: #000000c7 !important;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3c3675;
    border-bottom: 16px solid #3c3675;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* End loading animation */
  
  .not-found-data{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 100px 0;
    flex-direction: column;
  }

  
.bg-grey-footer {
  background-color: #F5F4F9 !important;
}

.home-bottom-line {
  width: 100%;
  height: 1px;
  background-color: #D8D6E4;
}

header .sticky-ld {
  background: #ffff;
}